import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import './MenuDrawer.scss';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-hot-toast';

import { Endpoint } from '../Models/Endpoint';
import {
  getUserInformation,
  UserInformationRequest,
} from 'services';

type Anchor = 'left';

type PageToNavigate = {
  title: string;
  path: string;
  loggedIn: boolean;
};

function MenuDrawer() {
  const navigate = useNavigate();
  const { loading, responseData } = getUserInformation();
  const [user, setUser] = React.useState<UserInformationRequest>();
  const location = useLocation();
  const [state, setState] = React.useState({
    left: false,
  });
  const token = !localStorage.getItem('token');

  const navigateWithAction = (info: PageToNavigate) => {
    if (info.title === 'Sign Out') {
      localStorage.removeItem('token');
      localStorage.removeItem('expiration');
      toast.success('Successfully signed out!');
      window.location.reload();
    }
    navigate(info.path);
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (responseData) {
      setUser(responseData);
    }
  }, [setUser, responseData, loading, location]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {user?.email}
      <List>
        {[
          { title: 'Home', path: Endpoint.MAIN_PAGE, loggedIn: true },
          { title: 'Profile', path: Endpoint.PROFILE, loggedIn: !token },
          { title: 'Login', path: Endpoint.LOGIN, loggedIn: token },
          { title: 'Register', path: Endpoint.REGISTER, loggedIn: token },
          { title: 'About us', path: Endpoint.ABOUT_US, loggedIn: true },
          {
            title: 'How does JustDogBox work?',
            path: Endpoint.ABOUT_PRODUCT,
            loggedIn: true,
          },
          {
            title: 'Get JustDogBox',
            path: Endpoint.ORDER_PRODUCT,
            loggedIn: true,
          },
          { title: 'Contact us', path: Endpoint.CONTACT, loggedIn: true },
          { title: 'FAQ', path: Endpoint.FAQ, loggedIn: true },
          { title: 'Sign Out', path: Endpoint.MAIN_PAGE, loggedIn: !token },
        ].map((text: PageToNavigate) => (
          <>
            <div key={text.title}>
              {text.loggedIn && (
                <ListItem key={text.title} disablePadding>
                  <ListItemButton onClick={() => navigateWithAction(text)}>
                    <ListItemText primary={text.title} />
                  </ListItemButton>
                </ListItem>
              )}
            </div>
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="left">
        <Menu sx={{ fontSize: 48 }} onClick={toggleDrawer('left', true)} />
        <SwipeableDrawer
          anchor="left"
          open={state['left']}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', false)}
        >
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export { MenuDrawer };
