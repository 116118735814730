import { Instagram, Facebook } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Endpoint } from 'shared';
import './Footer.scss';

function Footer() {
  const [hide, setHide] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/login') {
      setHide(true);
    } else if (location.pathname === '/register') {
      setHide(true);
    } else if (location.pathname.includes('/profile')) {
      setHide(true);
    } else if (location.pathname === 'payment-error') {
      setHide(true);
    } else if (location.pathname === 'payment-success') {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [location]);

  return (
    <div className="footer">
      {hide !== true && (
        <>
          <div className="footer-header">
            <h1>About JustDogBox</h1>
            <div className="footer-logo">
              <img
                src={require('./footer-logo.png')}
                srcSet={require('./footer-logo.png')}
                alt="Footer logo"
              />
            </div>
          </div>
          <div className="footer-links">
            <div
              className="footer-link"
              onClick={() => navigate(Endpoint.CONTACT)}
            >
              Contact Us
            </div>
            <div
              className="footer-link"
              onClick={() => navigate(Endpoint.ABOUT_PRODUCT)}
            >
              More from JustDogBox
            </div>
            <div className="footer-link">
              Follow us
              <a
                href="https://www.facebook.com/profile.php?id=100089601083151"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook sx={{ fontSize: 34 }} />
              </a>
              <a
                href="https://www.instagram.com/justdogbox/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram sx={{ fontSize: 34 }} />
              </a>
            </div>
          </div>
          <div className="creator">2023 by Edvinas Alimas</div>
        </>
      )}
    </div>
  );
}

export { Footer };
