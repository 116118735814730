import { Error } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router';

import { SToMainPageButton } from 'shared';
import './PaymentError.scss';

function PaymentError() {
  const navigation = useNavigate();
  return (
    <div className="payment-error">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Error style={{ fill: '#ff0000', fontSize: '84px' }} />
      </Box>
      <h1>Error Processing Payment</h1>
      <h2>Please check your security code, card details and connection and try again.</h2>
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
        <SToMainPageButton onClick={() => navigation('/')}>Back To Main Page</SToMainPageButton>
      </Box>
    </div>
  );
}

export { PaymentError };
