import React from 'react';
import { useNavigate } from 'react-router';

import { Endpoint } from '../Models/Endpoint';
import { SSubmitButton, SCancelButton } from '../StyledComponents/Buttons';

import './ProfileFormButtons.scss';

function ProfileFormButtons() {
  const navigate = useNavigate();

  return (
    <div className='profile-form-buttons'>
      <div className='cancel-button'>
        <SCancelButton variant='outlined' onClick={() => navigate(Endpoint.PROFILE)}>Cancel</SCancelButton>
      </div>
      <div className='profile-submit-button'>
        <SSubmitButton type='submit' variant="contained">
          Save
        </SSubmitButton>
      </div>
    </div>
  );
}

export { ProfileFormButtons };
