import { RawAxiosRequestHeaders } from "axios";

export const getUrl = (): string | undefined => {
  return `${process.env.REACT_APP_ENDPOINT ?? ""}`;
};

export const getToken = async (): Promise<RawAxiosRequestHeaders> => {
  try {
    const token = await localStorage.getItem("token");
    return {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    };
  } catch (error) {
    console.debug({ error });
    return {};
  }
};
