import { useParams } from "react-router";

import { SHeaderWithButton, Endpoint, SBoxWithOutlinedBorder } from "shared";

function UpdateCurrentAnimalSubscription() {

   const { id } = useParams();

   return (
      <div>
         <SHeaderWithButton
            buttonText='Back'
            headerText={`Select Subscription`}
            navigationLink={`${Endpoint.PROFILE_VIEW_INFORMATION}/${id}`} />

         <SBoxWithOutlinedBorder>

         </SBoxWithOutlinedBorder>
      </div>
   );
}

export { UpdateCurrentAnimalSubscription };
