import React from 'react';
import { useNavigate } from 'react-router';

import { SCancelButton } from '../StyledComponents/Buttons';

import './SHeaderWithButton.scss';

interface SHeaderWithButtonProps {
   buttonText: string;
   navigationLink: string;
   headerText: string;
}

function SHeaderWithButton(props: SHeaderWithButtonProps) {
   const navigate = useNavigate();

   return (
      <div>
         <div className='header-with-title-and-button'>
            <div className='header-text'>
               <h1>{props.headerText}</h1>
            </div>
            <div className='button-with-action'>
               <SCancelButton variant='outlined' onClick={() => navigate(props.navigationLink)}>{props.buttonText}</SCancelButton>
            </div>
         </div>
      </div>
   );
}

export { SHeaderWithButton };
