import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router';
import type { Router as RemixRouter } from '@remix-run/router';
import { createBrowserRouter } from 'react-router-dom';

import { routes } from './routes';
import { mainTheme } from './utils/theme';

import './App.scss';


function App() {
  const createdRoutes: RemixRouter = createBrowserRouter(routes);
  return (
    <ThemeProvider theme={mainTheme}>
      <Toaster position="bottom-center" />
      <CssBaseline />
      <RouterProvider router={createdRoutes} />
    </ThemeProvider>
  );
}

export { App };
