import { usePost, usePut, useGet } from 'utils';
import { SubscriptionPayment } from 'shared';

export interface RegisterRequest {
    token: string;
}

export interface LoginRequest {
    token: string;
    expiration: Date;
}

export interface UserInformationRequest {
    firstName: string;
    lastName: string;
    address: string;
    age: string;
    email: string;
}

export const postRegisterForm = () => usePost<RegisterRequest>('/register');

export const postLoginForm = () => usePost<LoginRequest>('/login');

export const putChangePasswordForm = () => usePut('/password');

export const putEditProfileForm = () => usePut('/user');

export const postSubscribeToEmailForm = () => usePost('/subscribe');

export const getUserInformation = () => useGet<UserInformationRequest>('/user'); 

export const getUserSubscriptions = () => useGet<SubscriptionPayment[]>('/subscriptions'); 