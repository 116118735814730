import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const SButton = styled(Button)(() => ({
    background: '#F6E5DC',
    border: '1px solid #5C5C5C',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    fontWeight: 700,
    fontSize: 18,
    justifyContent: 'left'
}));

export const SToMainPageButton = styled(Button)(() => ({
    background: '#F6E5DC',
    border: '1px solid #5C5C5C',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    color: '#000000',
    fontWeight: 700,
    fontSize: 18,
    justifyContent: 'center',
    '&:hover': { backgroundColor: '#F6E5DC' }
}));

export const SEditButton = styled(Button)(() => ({
    background: '#F6E5DC',
    border: '1px solid #5C5C5C',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    fontWeight: 700,
    fontSize: 16
}));

export const SSubmitButton = styled(Button)(() => ({
    background: '#F6E5DC',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    fontWeight: 700,
    fontSize: 14
}));

export const SCancelButton = styled(Button)(() => ({
    color: "#000000",
    border: '1px solid #5C5C5C',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    fontWeight: 700,
    fontSize: 14
}));

export const SDeleteButton = styled(Button)(() => ({
    color: "#ffffff",
    border: '1px solid #5C5C5C',
    background: '#9f0000',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    fontWeight: 700,
    fontSize: 14
}));

export const STypeButton = styled(Button)(() => ({
    color: "#000000",
    border: '1px solid #5C5C5C',
    borderRadius: 15,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    fontWeight: 700,
    fontSize: 14
}));

export const STypeSuccessButton = styled(Button)(() => ({
    backgroundColor: "#6D8701",
    border: '1px solid #5C5C5C',
    borderRadius: 15,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    fontWeight: 700,
    fontSize: 14,
    '&:hover': {
        backgroundColor: "#6D8701",
    },
}));