import { Button, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { postSubscribeToEmailForm } from 'services';
import { Endpoint } from 'shared';

import './HomeComponent.scss';

type SignUpForEmailForm = {
  email: string;
};

function HomeComponent() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForEmailForm>();
  const { apiCall, responseData, error, responseStatus, loading } =
    postSubscribeToEmailForm();

  const onSubmit = (data: SignUpForEmailForm) => apiCall(data);

  useEffect(() => {
    if (!loading && responseStatus === 200) {
      toast.success('Succesfully signed up!');
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Failed to subscribe or already subscribed!');
    }
  }, [loading, responseStatus, navigate, responseData, error]);

  return (
    <div>
      <section className="first-section-color">
        <div className="home">
          <img
            src={require('./home-first.webp')}
            srcSet={require('./home-first.webp')}
            alt="Feet logo"
          />
        </div>
        <h1 className="home-first-title">
          Your dog happiness, delivered monthly
        </h1>
        <h2 className="home-first-description">
          A totally customized box of themed toys and treats for your pup—every
          month.
        </h2>
      </section>
      <section className="second-section-color">
        <div>
          <img
            src={require('./home-second.webp')}
            srcSet={require('./home-second.webp')}
            alt="Dog under blanket"
          />
        </div>
        <div className="home-second-button">
          <Button
            variant="contained"
            onClick={() => navigate(Endpoint.ORDER_PRODUCT)}
          >
            GET JustDogBox
          </Button>
        </div>
        <div
          className="home-second-gift"
          onClick={() => navigate(Endpoint.ORDER_PRODUCT_GIFT)}
        >
          Give a gift
        </div>
      </section>
      <section>
        <h1 className="home-first-title">How does JustDogBox works?</h1>
        <div className="home-second-background">
          <div>
            <h1>1. Tell us about your dog</h1>
          </div>
          <div>
            <h2>Let us know your dog name, age, size and preferences. </h2>
          </div>
          <div>
            <img
              src={require('./home-third.webp')}
              srcSet={require('./home-third.webp')}
              alt="Dog laying on floor"
            />
          </div>
          <div>
            <h1>2. Choose a subscription</h1>
          </div>
          <div>
            <h2>
              You can choose how often you will get JustDogBox. Every month, 3
              month, 6 month or.{' '}
            </h2>
          </div>
          <div>
            <img
              src={require('./home-fourth.webp')}
              srcSet={require('./home-fourth.webp')}
              alt="Dog upside down"
            />
          </div>
          <div>
            <h1>3. We ship your order immediately</h1>
          </div>
          <div>
            <h2>You can choose which day do you want get JustDogBox.</h2>
          </div>
          <div>
            <h1>4. Be ready for your pet to eat, play and love</h1>
          </div>
          <div>
            <img
              src={require('./home-fifth.webp')}
              srcSet={require('./home-fifth.webp')}
              alt="Dog with ball"
            />
          </div>
          <div className="home-third-button">
            <Button
              variant="contained"
              onClick={() => navigate(Endpoint.ORDER_PRODUCT)}
            >
              GET JustDogBox
            </Button>
          </div>
        </div>
      </section>
      <section>
        <div>
          <h1>What is an JustDogBox?</h1>
        </div>
        <div className="home-fix-image">
          <img
            src={require('./home-sixth.webp')}
            srcSet={require('./home-sixth.webp')}
            alt="Inside of box"
          />
        </div>
        <div>
          <img
            src={require('./home-seventh.webp')}
            srcSet={require('./home-seventh.webp')}
            alt="Dog with chew toy"
          />
        </div>
      </section>
      <section>
        <div>
          <h1>Our Pack Has Your Back!</h1>
        </div>
        <div>
          <h2>
            If your dog isn't 100% happy with their JustDogBox, we’ll work with
            you to make it right. No muss, no fuss, no disappointed pups.
          </h2>
        </div>
        <div className="home-third-button">
          <Button
            variant="contained"
            onClick={() => navigate(Endpoint.ORDER_PRODUCT)}
          >
            GET JustDogBox
          </Button>
        </div>
        <div>
          <h1>Sign up for exclusive JustDogBox discounts, content and more!</h1>
        </div>
        <form
          className="home-fourt-text-field"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="Email address"
            variant="outlined"
            {...register('email', { required: 'Email required' })}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
          <div className="sign-up-button">
            <Button variant="contained" type="submit">
              Sign Up
            </Button>
          </div>
        </form>
      </section>
    </div>
  );
}

export { HomeComponent };
