import React from 'react';
import { FormControl, TextField } from '@mui/material';
import {
  FieldError,
  FieldName,
  FieldValues,
  Merge,
  RegisterOptions,
  UseFormRegister,
  ValidationRule,
} from 'react-hook-form';

export interface STextInput {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  variant?: 'standard' | 'filled' | 'outlined';
  isRequired?: boolean;
  inputProps?: Record<string, string | number>;
  errors?: Merge<FieldError, (FieldError | undefined)[]>;
  customValidation?: ValidationRule<number | string>;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
  shrink?: boolean;
}

export function STextInput(props: STextInput) {
  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
  > = {};

  const inputProps = {
    maxLength: 50,
    ...props.inputProps,
  };

  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
  }

  if (props.min) {
    validationOptions.min = props.min;
  }

  if (props.max) {
    validationOptions.max = props.max;
  }

  return (
    <FormControl sx={{ padding: '10px 20px 5px 20px' }} fullWidth>
      <TextField
        label={props.label}
        variant={"outlined"}
        {...props.register(props.name, validationOptions)}
        InputLabelProps={{ shrink: true }}
        inputProps={inputProps}
        error={Boolean(props.errors)}
        helperText={props.errors?.message}
        required={Boolean(props.isRequired)}
      />
    </FormControl>
  );
}

export { STextInput as StyledTextField };