import { useState } from 'react';

import {
  Endpoint,
  SHeaderWithButton,
  SubscriptionPayment
} from 'shared';

import './History.scss';

function History() {
  const [history, setHistory] = useState<SubscriptionPayment[]>([]);
  setHistory([]);

  return (
    <div>
      <SHeaderWithButton
        buttonText='Back'
        headerText='History of Payments'
        navigationLink={Endpoint.PROFILE} />
      <div className='history-information-wrapper'>
        <div className='history-payment-wrapper'>
          <div className='history-payment'>
            <div className='history-payment-header'>Payment Date</div>
            <div className='history-payment-value'>2023-03-11</div>
            <div className='history-payment-header'>Animal</div>
            <div className='history-payment-value'>Reksas</div>
          </div>
          <div className='history-amount-wrapper'>
            <div className='history-amount-header'>Amount</div>
            <div className='history-amount-value'>29,99€</div>
          </div>
        </div>
      </div>
      {history.length === 0 &&
        <div className='history-empty'><h1>NO HISTORY OF PAYMENTS</h1></div>
      }
    </div>
  );
}

export { History };
