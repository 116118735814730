import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import toast from 'react-hot-toast';

import { putAddAnimalForm, getDogById } from 'services';
import {
  SHeaderWithButton,
  AnimalForm,
  Endpoint
} from 'shared';
import { ProfileAnimalForm } from '../ProfileAnimalForm/ProfileAnimalForm';

import './ProfileEditAnimal.scss';

function ProfileEditAnimal() {
  const navigate = useNavigate();

  const { id } = useParams();

  const { loading: dogLoading, responseStatus: dogStatus, responseData } = getDogById(id || '');

  const { apiCall, error, loading, responseStatus } =
    putAddAnimalForm();

  const onSubmit = (data: AnimalForm, id?: string, email?: string) => {
    if (id && email) {
      apiCall({ ...data, id, email });
      return;
    }
    apiCall(data);
  };

  useEffect(() => {
    if (!loading && responseStatus === 200) {
      toast.success('Succesfully updated dog!');
      navigate(Endpoint.PROFILE_LIST_OF_ANIMALS);
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Something went wrong, could not add dog profile!');
    }
    if (dogLoading) {
      return;
    }
    if (!dogLoading && dogStatus === 200) {
    }
  }, [loading, error, responseStatus, navigate, dogLoading, dogStatus, responseData]);

  return (
    <div className="profile-add-animal">
      <div className='profile-update-animal-header'>
        <SHeaderWithButton
          buttonText='Cancel'
          headerText='Update Information'
          navigationLink={Endpoint.PROFILE_LIST_OF_ANIMALS} />
      </div>
      <div>
        <ProfileAnimalForm onSubmit={onSubmit} dogInformation={responseData?.animal} />
      </div>
    </div>
  );
}

export { ProfileEditAnimal };
