import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box } from '@mui/material';

import { getDogById } from 'services';
import {
  SButton,
  SStatusDisplay,
  SBoxWithOutlinedBorder,
  Endpoint,
  SHeaderWithButton,
  STypographyHeader,
  STypographyValue,
  SBoxWithPadding,
  Subscription,
  AnimalInformationByIdResponse,
} from 'shared';
import { Loading } from 'components/Loading/Loading';

import './ProfileViewAnimalInformation.scss';


function ProfileViewAnimalInformation() {
  const navigate = useNavigate();
  const [dog, setDog] = useState<AnimalInformationByIdResponse>();

  const { id } = useParams();
  const { error, loading, responseStatus, responseData } = getDogById(id || '');

  useEffect(() => {
    if (loading) {
      return;
    }
    if (responseData) {
      setDog(responseData);
    }

  }, [loading, responseData, navigate, error, responseStatus]);

  return (
    <div>
      {loading && !dog ? <Loading /> : (<>
        <SHeaderWithButton
          buttonText='Back'
          headerText={`Dog "${dog?.animal.name}"`}
          navigationLink={Endpoint.PROFILE_LIST_OF_ANIMALS} />
        <SBoxWithOutlinedBorder>
          <div>
            <div>
              <STypographyHeader>Birthday Date</STypographyHeader>
              <STypographyValue>{dog?.animal.birthdayDate}</STypographyValue>
            </div>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <STypographyHeader>Selected Subscription</STypographyHeader>
                <STypographyValue>{dog?.animal.subscription}</STypographyValue>
              </div>
              {dog?.subscription !== Subscription.NONE && (
                <div>
                  <STypographyHeader>Subscription Status</STypographyHeader>
                  <div>
                    <SStatusDisplay
                      status={dog?.isPaid ?? false}
                      text={dog?.subscription ?? 'Inactive'}
                    />
                  </div>
                </div>
              )}
            </Box>
            <div>
              <STypographyHeader>Weight Category</STypographyHeader>
              <STypographyValue>{dog?.animal.weightCategory}</STypographyValue>
            </div>
            <Box style={{ paddingTop: '15px' }}>
              <SBoxWithPadding>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_VIEW_INFORMATION_SUSBCRIPTION.replace(':id', id ?? ''))}>
                  Change/Select Subscription
                </SButton>
              </SBoxWithPadding>
              <SBoxWithPadding>
                <SButton fullWidth variant="contained" disabled={dog?.animal.subscription === Subscription.NONE} onClick={() => navigate(Endpoint.PROFILE_LIST_OF_ANIMALS)}>
                  Pay for subscription
                </SButton>
              </SBoxWithPadding>
              <SBoxWithPadding>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_LIST_OF_ANIMALS)}>
                  Remove “{dog?.animal.name}”
                </SButton>
              </SBoxWithPadding>
              <SBoxWithPadding>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_LIST_OF_ANIMALS)}>
                  Edit Dog Information
                </SButton>
              </SBoxWithPadding>
            </Box>
          </div>
        </SBoxWithOutlinedBorder>
      </>)}
    </div>
  );
}

export { ProfileViewAnimalInformation };
