import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { postRegisterForm } from 'services';
import {
  SDivider,
  Endpoint,
} from 'shared';

import './Register.scss';

export type RegisterForm = {
  firstName: string;
  lastName: string;
  birthdayDate: string;
  email: string;
  password: string;
};

function Register() {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);

  const navigate = useNavigate();

  const { apiCall, responseData, error, loading, responseStatus } =
    postRegisterForm();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RegisterForm>();
  const onSubmit = (data: RegisterForm) => apiCall(data);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleChangeOfDate = (newValue: Dayjs | null) => {
    setDateValue(newValue);
    setValue('birthdayDate', newValue ? newValue.toString() : '');
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (!loading && responseStatus === 200 && responseData !== undefined) {
      localStorage.setItem('token', responseData.token);
      toast.success('Account registered!');
      window.location.reload();
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Could not register!');
    }
  }, [loading, responseStatus, navigate, responseData, error]);

  return (
    <div className="login">
      <div className="login-header">
        <header>Register your account</header>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="email-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('firstName', { required: 'First Name required' })}
              label="First Name"
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message}
            />
          </FormControl>
        </div>
        <div className="email-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('lastName', { required: 'Last Name required' })}
              label="Last Name"
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message}
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ width: '94%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Birthday"
                inputFormat="YYYY-MM-DD"
                value={dateValue}
                onChange={handleChangeOfDate}
                renderInput={(params) => <TextField fullWidth required {...params} {...register('birthdayDate', {
                  required: 'Birthday is required'
                })}
                />}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="email-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('email', { required: 'Email required' })}
              label="Email"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          </FormControl>
        </div>
        <div className="password-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('password', { required: 'Password required' })}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              label="Password"
            />
          </FormControl>
        </div>
        <div className="login-button">
          <Button type="submit" fullWidth variant="contained">
            Register
          </Button>
        </div>
      </form>
      <div>
        <Button color="secondary" onClick={() => navigate(Endpoint.LOGIN)}>
          Already have an account ?
        </Button>
      </div>
      <div>
        <SDivider />
      </div>
      {/* <SocialButtons /> */}
    </div>
  );
}

export { Register };
