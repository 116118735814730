import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-hot-toast';

import {
  SCancelButton,
  Endpoint,
  AnimalForm,
  Subscription,
} from 'shared';
import {
  postAddAnimalForm,
  postNewSubscription,
  subscriptionPriceId
} from 'services';
import { ProfileAnimalForm } from '../ProfileAnimalForm/ProfileAnimalForm';

import './ProfileAddAnimal.scss';

function ProfileAddAnimal() {
  const [newAnimalId, setNewAnimalId] = useState<string | undefined>(undefined);
  const [subscriptionType, setSubscriptionType] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const { apiCall, loading: addAnimalLoading, responseStatus: addAnimalResponseStatus, error: addAnimalError, responseData: addAnimalResponseData } =
    postAddAnimalForm();
  const { apiCall: subscriptionApiCall, error, loading, responseStatus, responseData } =
    postNewSubscription();

  const onSubmit = (data: AnimalForm) => {
    setSubscriptionType(data.subscription);

    apiCall({
      name: data.name,
      type: data.type,
      birthdayDate: data.birthdayDate,
      weightCategory: data.weightCategory,
      subscription: data.subscription,
      allergies: data.allergies
    });

    if (data.subscription !== Subscription.NONE && newAnimalId) {
      const value: string = subscriptionPriceId(data.weightCategory);
      subscriptionApiCall({ ...data, productId: value, existingAnimalId: newAnimalId });
    }
  };

  useEffect(() => {
    if (!loading && responseStatus === 200 && responseData) {
      toast.success('Succesfully added dog!');
      localStorage.setItem('subscriptionId', responseData.subscriptionId);
      window.location.href = responseData.checkoutUrl;
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Something went wrong, could not add dog profile!');
    }
  }, [loading, error, responseStatus, navigate]);

  useEffect(() => {
    if (!addAnimalLoading && addAnimalResponseStatus === 200) {
      toast.success('Succesfully added dog!');

      console.log(addAnimalResponseData?.animalId);
      setNewAnimalId(addAnimalResponseData?.animalId);

      if (subscriptionType === Subscription.NONE) {
        navigate('/profile/list-of-animals');
      }
    }
    if (!addAnimalLoading && addAnimalError && addAnimalResponseStatus !== 200) {
      toast.error('Something went wrong, could not add dog profile!');
    }
  }, [addAnimalLoading, addAnimalError, addAnimalResponseStatus, navigate]);

  return (
    <div className="profile-add-animal">
      <div className='profile-add-animal-header-wrapper'>
        <div>
          <h1>Dog information</h1>
        </div>
        <div className='profile-add-animal-header-wrapper-button'>
          <SCancelButton variant="outlined" onClick={() => navigate(Endpoint.PROFILE_LIST_OF_ANIMALS)}>Cancel</SCancelButton>
        </div>
      </div>
      <div>
        <ProfileAnimalForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export { ProfileAddAnimal };
