import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
  palette: {
    background: {
      paper: '#D9D9D9'
    },
    primary: {
      main: '#F6E5DC',
      contrastText: "#000000"
    },
    text: {
      primary: '#000000',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    }
  },
  typography: {
    allVariants: {
      fontFamily: "'Comic Neue', sans-serif",
      textTransform: "none",
    }
  },
});