import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import {
  TextField,
  FormControl,
} from '@mui/material';

import { putEditProfileForm, getUserInformation } from 'services';
import { ProfileFormButtons, Endpoint } from 'shared';
import './EditProfile.scss';
import { Loading } from 'components/Loading/Loading';

type EditProfileForm = {
  firstName: string;
  lastName: string;
  address: string;
  age: string;
}

function EditProfile() {
  const navigate = useNavigate();
  const { apiCall, responseData, error, loading, responseStatus } =
    putEditProfileForm();
  const { loading: loadingUser, responseData: userData } = getUserInformation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditProfileForm>();

  const onSubmit = (data: EditProfileForm) => apiCall(data);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (loadingUser) {
      return;
    }
    if (!loadingUser && userData) {
      setValue('firstName', userData?.firstName);
      setValue('lastName', userData?.lastName);
      setValue('address', userData?.address);
      setValue('age', userData?.age);
    }

    if (!loading && responseStatus === 200) {
      toast.success('Succesfully updated information!');
      navigate(Endpoint.PROFILE);
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Something went wrong, could not update information!');
    }
  }, [loading, responseStatus, navigate, responseData, error, setValue, loadingUser, userData]);

  return (
    <div className="edit-profile">
      {loadingUser ? <Loading /> : (<>
        <h1>Edit Profile</h1>
        <div >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='edit-profile-form-wrapper'>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register('firstName', { required: 'First Name required' })}
                  label="First Name"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                />
              </FormControl>
            </div>
            <div className='edit-profile-form-wrapper'>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register('lastName', { required: 'Last Name required' })}
                  label="Last Name"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                />
              </FormControl>
            </div>
            <div className='edit-profile-form-wrapper'>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register('address')}
                  label="Address"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.address)}
                  helperText={errors.address?.message}
                />
              </FormControl>
            </div>
            <div className='edit-profile-form-wrapper'>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  {...register('age')}
                  label="Age"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.age)}
                  helperText={errors.age?.message}
                />
              </FormControl>
            </div>
            <ProfileFormButtons />
          </form>
        </div>
      </>)}
    </div>
  );
}

export { EditProfile };
