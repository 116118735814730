import './FrequentlyAskedQuestions.scss';

function FrequentlyAskedQuestions() {
  return (
    <div className="FrequentlyAskedQuestions">
      FrequentlyAskedQuestions Component
    </div>
  );
}

export { FrequentlyAskedQuestions };
