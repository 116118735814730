import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

import { Endpoint } from 'shared';
import './Contact.scss';

function Contact() {
  const navigate = useNavigate();
  const sendEmail = () => {
    window.location.href = 'mailto:infojustdogbox@gmail.com';
  };

  return (
    <div className="contact">
      <h1>Contact us</h1>
      <div>
        <h2>Got a question about JustDogBox?</h2>
        <div className="contact-button">
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate(Endpoint.FAQ)}
          >
            FAQ
          </Button>
        </div>
        <div className="contact-button">
          <Button fullWidth variant="contained" onClick={sendEmail}>
            Email us at infojustdogbox@gmail.com
          </Button>
        </div>
      </div>
    </div>
  );
}

export { Contact };
