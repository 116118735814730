import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

import './DeleteDialog.scss';
import { SCancelButton, SDeleteButton } from '../StyledComponents/Buttons';

export type DialogTitleProps = {
  id?: string;
  name?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (id?: string) => void;
}

function DeleteDialog(props: DialogTitleProps) {
  const handleSubmit = () => {
    props.onSubmit(props.id);
    handleClose();
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Dialog
        onClose={props.onClose}
        aria-labelledby="delete-dialog"
        open={props.open}>
        <DialogTitle id="responsive-dialog-title">
          {`Delete ${props.name} information ?`}
        </DialogTitle>
        <DialogActions>
          <SCancelButton variant="outlined" autoFocus onClick={handleClose}>
            Cancel
          </SCancelButton>
          <SDeleteButton onClick={handleSubmit} autoFocus>
            Delete
          </SDeleteButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
export { DeleteDialog };
