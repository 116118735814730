import './Loading.scss';

function Loading() {
  return (
    <div className="loading">
      <div>
        <img
          src={require('./spining.png')}
          srcSet={require('./spining.png')}
          alt="Dog with ball"
        />
      </div>
    </div>
  );
}

export { Loading };
