import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const STypographyHeader = styled(Typography)(() => ({
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.5)',
}));

export const STypographyValue = styled(Typography)(() => ({
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
}));