export enum Subscription {
  EVERY_MONTH = "Every month",
  EVERY_THREE_MONTHS = "Every three months",
  EVERY_SIX_MONTHS = "Every six months",
  ONCE = "Once",
  NONE = "None",
}

export enum WeightCategory {
  SMALL_DOG = "Small dog. 5 months. Weight 4kg",
  LARGE_DOG = "Large dog. 5 months. Weight 15kg",
  LARGER_DOG = "Large dog. 12 months. Weight 22kg",
  LARGEST_DOG = "Large dog. Weight 25kg",
}

export enum Type {
  GIRL = "Girl",
  BOY = "Boy",
}

export type AnimalForm = {
  name: string;
  type: string;
  birthdayDate: string;
  weightCategory: string;
  subscription: string;
  allergies: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  buildingNumber: string;
  city: string;
  province: string;
  country: string;
  postCode: string;
  email: string;
  delivery: string;
};

export type AnimalInformationResponse = {
  items: AnimalInformation[];
};

export type AnimalInformationByIdResponse = {
  animal: AnimalInformation;
  subscription: string;
  isPaid: boolean;
};

export type AnimalInformation = {
  id: string;
  name: string;
  email: string;
  type: string;
  birthdayDate: string;
  weightCategory: string;
  subscription: string;
  allergies: string;
};



export type SubscriptionPayment = {
  id: string;
  email: string;
  name: string;
  paymentStatus: string;
  creationDate: string;
  amountTotal: string;
  mode: string;
};
