import axios, { Method } from 'axios';
import { useState, useCallback } from 'react';

import { getToken, getUrl } from './authorization';

interface ApiResponse<T> {
    responseData: T | undefined;
    responseStatus: number | undefined;
    error: string | undefined;
    loading: boolean;
    apiCall: (payload: Record<string, string | number | object>, pathParameter?: string) => Promise<void>;
}

const usePut = <T>(url: string): ApiResponse<T> => {
    return useRequest(url, 'PUT');
};

const usePost = <T>(url: string): ApiResponse<T> => {
    return useRequest(url, 'POST');
};

const useDelete = <T>(url: string): ApiResponse<T> => {
    return useRequest(url, 'DELETE');
};

const useRequest = <T>(url: string, method: Method): ApiResponse<T> => {
    const [responseData, setResponseData] = useState<T>();
    const [responseStatus, setResponseStatus] = useState<number>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const host = getUrl();
    let updatedUrl = `${host}${url}`;

    const apiCall = useCallback(
        async (payload: Record<string, string | number | object>, pathParameter?: string) => {
            if (!payload && !pathParameter) {
                return;
            }

            if (pathParameter) {
                updatedUrl = `${host}${url}`;
                updatedUrl = updatedUrl + `/${pathParameter}`;
            }

            setLoading(true);
            axios
                .request({
                    data: payload,
                    headers: await getToken(),
                    method,
                    url: updatedUrl,
                })
                .then((response) => {
                    setResponseData(response.data);
                    setResponseStatus(response.status);
                })
                .catch((error) => setError(error.message))
                .finally(() => setLoading(false));
        },
        [method, updatedUrl]
    );
    return { apiCall, responseData, error, loading, responseStatus };
};

export { usePost, usePut, useDelete };