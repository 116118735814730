import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';

import { getUserSubscriptions } from 'services';
import {
  SStatusDisplay,
  SBoxWithOutlinedBorder,
  SHeaderWithButton,
  SubscriptionPayment,
  SEditButton,
  Endpoint
} from 'shared';

import './Subscription.scss';

function Subscription() {
  const [subscriptions, setSubscriptions] = useState<SubscriptionPayment[]>([]);
  const navigate = useNavigate();

  const { responseData, loading } = getUserSubscriptions();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (loading) {
      return;
    }
    if (responseData) {
      setSubscriptions(responseData);
    }
  }, [setSubscriptions, responseData, loading]);


  const transformDate = (date: string) => {
    return date ? new Date(date) : new Date();
  };

  return (
    <div>
      <Box>
        <SHeaderWithButton
          buttonText='Back'
          headerText='Subscriptions'
          navigationLink={Endpoint.PROFILE} />
      </Box>
      <Box>
        {subscriptions && subscriptions.map((subscription: SubscriptionPayment) => (
          <SBoxWithOutlinedBorder key={subscription.id}>
            <div className='subscription-text'>
              <div>
                <div className='subscription-header'>Starting Date</div>
                <div className='subscription-value'>{subscription.creationDate ? transformDate(subscription.creationDate).toISOString().slice(0, 10) : ''}</div>
              </div>
              <div>
                <div className='subscription-header'>Type</div>
                <div className='subscription-value'>{subscription.mode}</div>
              </div>
              <div>
                <div className='subscription-header'>Animal</div>
                <div className='subscription-value'>Reksas</div>
              </div>
              <div>
                <div className='subscription-header'>Status</div>
                <div className='subscription-value with-status'>
                  <>
                    <SStatusDisplay
                      status={subscription.paymentStatus === 'paid' ? true : false}
                      text={subscription.paymentStatus === 'paid' ? 'Paid' : 'Not Paid'}
                    />
                  </>
                </div>
              </div>
            </div>
            <div className='subscription-buttons'>
              <div className='subscription-edit-button'>
                <SEditButton variant="contained" onClick={() => navigate(Endpoint.PROFILE)}>Edit</SEditButton>
              </div>
              <div>
                <SEditButton variant="contained" disabled={true} onClick={() => navigate(Endpoint.PROFILE)}>Deactivate</SEditButton>
              </div>
            </div>
          </SBoxWithOutlinedBorder>
        ))}
        {subscriptions.length === 0 &&
          <div className='history-empty'><h1>NO SUBSCRIPTIONS</h1></div>
        }
      </Box>
    </div>
  );
}

export { Subscription };
