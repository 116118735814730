import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  FormHelperText
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import {
  ButtonSelectionChange,
  StyledTextField,
  ShippingInformationForm,
  SFormControl,
  SSubmitButton,
  SCancelButton,
  Subscription,
  WeightCategory,
  Type,
  AnimalForm,
  AnimalInformation
} from 'shared';

import './ProfileAnimalForm.scss';

interface ProfileAnimalFormProps {
  onSubmit: (values: AnimalForm, id?: string, email?: string) => void;
  dogInformation?: AnimalInformation;
}

function ProfileAnimalForm(props: ProfileAnimalFormProps) {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedType, setSelectedType] = useState<string>();
  const [selectedWeight, setSelectedWeight] = useState<string>();
  const [selectedSubscription, setSelectedSubscription] = useState<string>();
  const [displayTypeError, setDisplayTypeError] = useState<boolean>(false);
  const [displayWeightError, setDisplayWeightError] = useState<boolean>(false);
  const [displaySubscriptionError, setDisplaySubscriptionError] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);

  const handleSelectedType = (type: string) => {
    setSelectedType(type);
    setValue('type', type);
  };

  const handleSelectedWeightCategory = (weight: string) => {
    setSelectedWeight(weight);
    setValue('weightCategory', weight);
  };

  const handleSelectedSubscription = (subscription: string) => {
    setSelectedSubscription(subscription);
    setValue('subscription', subscription);
  };

  const handleChangeOfDate = (newValue: Dayjs | null) => {
    setDateValue(newValue);
    setValue('birthdayDate', newValue ? newValue.toString() : '');
  };

  const handleChangeStep = (nextStep: number) => {
    trigger('name');
    trigger('birthdayDate');
    if (!selectedType && nextStep === 2) {
      setDisplayTypeError(true);
      return;
    }
    if (!selectedWeight && nextStep === 3) {
      trigger('allergies');
      setDisplayWeightError(true);
      return;
    }

    setDisplayWeightError(false);
    setDisplayTypeError(false);
    setCurrentStep(nextStep);
  };

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState,
  } = useForm<AnimalForm>();

  const onSubmit = (data: AnimalForm) => {
    if (!selectedSubscription) {
      setDisplaySubscriptionError(true);
      return;
    }
    props.onSubmit(data, props.dogInformation?.id, props.dogInformation?.email);
  };

  useEffect(() => {
    if (!getValues('country')) {
      setValue('country', 'Lithuania');
    }
    if (props.dogInformation) {
      handleSelectedType(props.dogInformation.type);
      handleSelectedWeightCategory(props.dogInformation.weightCategory);
      handleSelectedSubscription(props.dogInformation.subscription);
      handleChangeOfDate(dayjs(props.dogInformation.birthdayDate));
      setValue('name', props.dogInformation.name);
      setValue('allergies', props.dogInformation.allergies);
    }
  }, [props.dogInformation]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 1 &&
          (<>
            <StyledTextField
              name="name"
              label="Name"
              errors={formState.errors.name}
              isRequired={true}
              register={register}
            />
            <div>
              <SFormControl fullWidth>
                <div className='animal-information-type'>
                  <ButtonSelectionChange
                    selectedOption={Type.GIRL.toString()}
                    displayAsSelected={selectedType === Type.GIRL.toString()}
                    displayValue={'Girl'}
                    handleSelection={handleSelectedType}
                  />
                  <div className='animal-information-type-or'>OR</div>
                  <ButtonSelectionChange
                    selectedOption={Type.BOY.toString()}
                    displayAsSelected={selectedType === Type.BOY.toString()}
                    displayValue={'Boy'}
                    handleSelection={handleSelectedType}
                  />
                </div>
                {displayTypeError &&
                  <FormHelperText error>Selecte BOY or GIRL, required</FormHelperText>
                }
              </SFormControl>
            </div>
            <div>
              <SFormControl sx={{ width: '100%' }} color='secondary'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Birthday"
                    inputFormat="YYYY-MM-DD"
                    value={dateValue}
                    onChange={handleChangeOfDate}
                    renderInput={(params) => <TextField fullWidth required {...params} {...register('birthdayDate', {
                      required: 'Birthday is required'
                    })}
                    />}
                  />
                </LocalizationProvider>
              </SFormControl>
            </div>
          </>)
        }
        {currentStep === 2 && (<>
          <SFormControl fullWidth>
            <div><strong>How big is your dog?</strong> <span className='minor-text'>(choose one, you can change at any time)</span></div>
            <div className='weight-category-wrapper'>
              <div>
                <ButtonSelectionChange
                  selectedOption={WeightCategory.SMALL_DOG.toString()}
                  displayAsSelected={selectedWeight === WeightCategory.SMALL_DOG.toString()}
                  displayValue={'Small dog. 5 months. Weight 4kg'}
                  handleSelection={handleSelectedWeightCategory}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={WeightCategory.LARGE_DOG.toString()}
                  displayAsSelected={selectedWeight === WeightCategory.LARGE_DOG.toString()}
                  displayValue={'Large dog. 5 months. Weight 15kg'}
                  handleSelection={handleSelectedWeightCategory}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={WeightCategory.LARGER_DOG.toString()}
                  displayAsSelected={selectedWeight === WeightCategory.LARGER_DOG.toString()}
                  displayValue={'Large dog. 12 months. Weight 22kg'}
                  handleSelection={handleSelectedWeightCategory}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={WeightCategory.LARGEST_DOG.toString()}
                  displayAsSelected={selectedWeight === WeightCategory.LARGEST_DOG.toString()}
                  displayValue={'Large dog. Weight 25kg'}
                  handleSelection={handleSelectedWeightCategory}
                />
              </div>
            </div>
            {displayWeightError &&
              <FormHelperText error>Select weight, required!</FormHelperText>
            }
          </SFormControl>
          <StyledTextField
            name="allergies"
            label="Allergies"
            errors={formState.errors.allergies}
            isRequired={true}
            register={register}
          /></>)}
        {currentStep === 3 && (<>
          <SFormControl fullWidth>
            <div><strong>You can choose subscription</strong> <span className='minor-text'>(choose one, you can change at any time)</span></div>
            <div className='weight-category-wrapper'>
              <div>
                <ButtonSelectionChange
                  selectedOption={Subscription.EVERY_MONTH.toString()}
                  displayAsSelected={selectedSubscription === Subscription.EVERY_MONTH.toString()}
                  displayValue={'Every month'}
                  handleSelection={handleSelectedSubscription}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={Subscription.EVERY_THREE_MONTHS.toString()}
                  displayAsSelected={selectedSubscription === Subscription.EVERY_THREE_MONTHS.toString()}
                  displayValue={'Every three months'}
                  handleSelection={handleSelectedSubscription}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={Subscription.EVERY_SIX_MONTHS.toString()}
                  displayAsSelected={selectedSubscription === Subscription.EVERY_SIX_MONTHS.toString()}
                  displayValue={'Every six months'}
                  handleSelection={handleSelectedSubscription}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={Subscription.ONCE.toString()}
                  displayAsSelected={selectedSubscription === Subscription.ONCE.toString()}
                  displayValue={'Once'}
                  handleSelection={handleSelectedSubscription}
                />
              </div>
              <div>
                <ButtonSelectionChange
                  selectedOption={Subscription.NONE.toString()}
                  displayAsSelected={selectedSubscription === Subscription.NONE.toString()}
                  displayValue={'None'}
                  handleSelection={handleSelectedSubscription}
                />
              </div>
            </div>
            {displaySubscriptionError &&
              <FormHelperText error>Select subscription, required!</FormHelperText>
            }
          </SFormControl>
        </>)}
        {getValues('subscription') !== Subscription.NONE && currentStep === 4 && (<ShippingInformationForm register={register} formState={formState}></ShippingInformationForm>)}
        <div className='add-animal-action-button-wrapper'>
          <SCancelButton variant="outlined" disabled={currentStep === 1} onClick={() => setCurrentStep(currentStep - 1)}>Back</SCancelButton>
          {getValues('subscription') === Subscription.NONE && currentStep === 3 &&
            (
              <SSubmitButton type='submit' variant="contained">Submit</SSubmitButton>
            )
          }
          {currentStep === 4 &&
            (
              <SSubmitButton type='submit' variant="contained">Submit</SSubmitButton>
            )
          }
          {getValues('subscription') !== Subscription.NONE && currentStep !== 4 &&
            (
              <SSubmitButton variant="contained" onClick={() => handleChangeStep(currentStep + 1)} >Next</SSubmitButton>
            )
          }
        </div>
      </form>
    </div>
  );
}

export { ProfileAnimalForm };
