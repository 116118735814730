import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import {
  getUserInformation,
  UserInformationRequest,
} from 'services';
import {
  SBoxWithOutlinedBorder,
  SHeaderWithButton,
  Endpoint,
  SButton,
} from 'shared';
import { Loading } from '../Loading/Loading';

import './Profile.scss';

function Profile() {
  const navigate = useNavigate();
  const { loading, responseData } = getUserInformation();
  const [user, setUser] = React.useState<UserInformationRequest>();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (loading) {
      return;
    }
    if (responseData) {
      setUser(responseData);
    }
  }, [setUser, responseData, loading]);

  const calculateAge = (date: string) => {
    const now = new Date();
    const birthDate = new Date(date);
    let age = now.getFullYear() - birthDate.getFullYear();
    const monthDiff = now.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  return (
    <>
      {loading ? <Loading /> : (
        <div>
          <div>
            <SHeaderWithButton
              buttonText='Edit'
              headerText='Profile'
              navigationLink={Endpoint.PROFILE_EDIT} />
          </div>
          <div>
            <SBoxWithOutlinedBorder>
              <div className='profile-information-wrapper'>
                <div className='profile-information-title'>Email</div>
                <div className='profile-information-value'>{user?.email}</div>
              </div>
              <div className='profile-information-wrapper'>
                <div className='profile-information-title'>Full name</div>
                <div className='profile-information-value'>{user?.firstName} {user?.lastName}</div>
              </div>
              {user?.address &&
                <div className='profile-information-wrapper'>
                  <div className='profile-information-title'>Address</div>
                  <div className='profile-information-value'>{user?.address}</div>
                </div>
              }
              {user?.age &&
                <div>
                  <div className='profile-information-title'>Age</div>
                  <div className='profile-information-value'>{calculateAge(user?.age)}{` (${user?.age})`}</div>
                </div>
              }
            </SBoxWithOutlinedBorder>
          </div>
          <div className="profile">
            <div className='profile-buttons'>
              <div className='profile-button-wrapper'>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_LIST_OF_ANIMALS)}>
                  List of dogs
                </SButton>
              </div>
              <div className='profile-button-wrapper'>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_HISTORY)}>
                  History of JustDogBox payments
                </SButton>
              </div>
              <div className='profile-button-wrapper'>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_SUBSCRIPTION)}>
                  JustDogBox Subscription
                </SButton>
              </div>
              <div className='profile-button-wrapper'>
                <SButton fullWidth variant="contained" onClick={() => navigate(Endpoint.PROFILE_CHANGE_PASSWORD)}>
                  Change password
                </SButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>);
}

export { Profile };
