import React, { ReactNode } from 'react';
import './SBoxWithOutlinedBorder.scss';

interface SBoxWithOutlinedBorderProps {
   children: ReactNode;
}

function SBoxWithOutlinedBorder(props: SBoxWithOutlinedBorderProps) {
   return (
      <div className='box-border'>
         {props.children}
      </div>
   );
   }
   export { SBoxWithOutlinedBorder };
