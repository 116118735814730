import { styled } from "@mui/material/styles";
import { FormControl, FormControlLabel } from "@mui/material";

export const SFormControl = styled(FormControl)(() => ({
    padding: '10px 20px 5px 20px'
}));

export const SFormControlLabel = styled(FormControlLabel)(() => ({
    border: '1px solid #d6d4d4',
    borderRadius: '5px',
    padding: '5px 0',
    marginBottom: '6px',
    marginLeft: '2px'
}));