import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { postLoginForm } from 'services';
import {
  SDivider,
  Endpoint
} from 'shared';

import './Login.scss';

export type LoginForm = {
  email: string;
  password: string;
};

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { apiCall, responseData, error, loading, responseStatus } =
    postLoginForm();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();
  const onSubmit = (data: LoginForm) => apiCall(data);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (!loading && responseStatus === 200 && responseData !== undefined) {
      localStorage.setItem('token', responseData.token);
      localStorage.setItem('expiration', String(responseData.expiration));
      toast.success('Succesfully loged in!');
      window.location.reload();
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Could not log in, wrong credentials!');
    }
  }, [loading, responseStatus, navigate, responseData, error]);

  return (
    <div className="login">
      <div className="login-header">
        <header>Login in to your account</header>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="email-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('email', { required: 'Email required' })}
              label="Email"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          </FormControl>
        </div>
        <div className="password-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('password', { required: 'Password required' })}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              label="Password"
            />
          </FormControl>
        </div>
        <div className="login-button">
          <Button type="submit" fullWidth variant="contained">
            Login
          </Button>
        </div>
      </form>
      <div>
        <Button
          color="secondary"
          onClick={() => navigate(Endpoint.FORGOT_PASSWORD)}
        >
          Forgot password?
        </Button>
        <Button color="secondary" onClick={() => navigate(Endpoint.REGISTER)}>
          Not registered? Here!
        </Button>
      </div>
      <div>
        <SDivider />
      </div>
      {/* <SocialButtons /> */}
    </div>
  );
}

export { Login };
