import React, { useEffect, useState } from 'react';
import { MenuItem, TextField, RadioGroup, Radio } from '@mui/material';
import { FormState, UseFormRegister } from 'react-hook-form';
import { getCountries } from 'iso-3166-1-alpha-2';

import { SFormControl, SFormControlLabel } from '../StyledComponents/Forms';
import { AnimalForm } from '../Models/DogInformation';
import { StyledTextField } from '../StyledTextField/StyledTextField';

import './ShippingInformationForm.scss';

export interface ShippingInformationFormModel {
  firstName: string;
  lastName: string;
  streetAddress: string;
  buildingNumber: string;
  city: string;
  province: string;
  country: string;
  postCode: string;
  email: string;
  delivery: string;
}

export interface ShippingInformationFormProps {
  register: UseFormRegister<AnimalForm>;
  formState: FormState<AnimalForm>;
}

function ShippingInformationForm(props: ShippingInformationFormProps) {
  const [countries, setCountries] = useState<string[]>(['Lithuania']);

  useEffect(() => {
    setCountries(getCountries());
  }, [getCountries]);

  const {
    register,
    formState: { errors },
  } = props;

  return (
    <div className="shipping-information-form">
      <StyledTextField
        name="firstName"
        label="First Name"
        errors={errors.firstName}
        isRequired={true}
        register={register}
      />
      <StyledTextField
        name="lastName"
        label="Last Name"
        errors={errors.lastName}
        isRequired={true}
        register={register}
      />
      <StyledTextField
        name="streetAddress"
        label="Street Address"
        errors={errors.streetAddress}
        isRequired={true}
        register={register}
      />
      <StyledTextField
        name="buildingNumber"
        label="Apt/Suite"
        errors={errors.buildingNumber}
        register={register}
      />
      <StyledTextField
        name="city"
        label="City"
        errors={errors.city}
        isRequired={true}
        register={register}
      />
      <StyledTextField
        name="province"
        label="Province"
        errors={errors.province}
        isRequired={true}
        register={register}
      />
      <SFormControl fullWidth>
        <TextField
          fullWidth={true}
          variant='outlined'
          {...register('country', { required: 'Country required' })}
          label='Country'
          InputLabelProps={{ shrink: true }}
          error={Boolean(errors.country)}
          helperText={errors.country?.message}
          select
          defaultValue={'Lithuania'}>
          {countries.map((country) => (
            <MenuItem key={country} value={country}>{country}</MenuItem>
          ))}
        </TextField>
      </SFormControl>
      <StyledTextField
        name="postCode"
        label="Zip/Postal Code"
        errors={errors.postCode}
        isRequired={true}
        register={register}
      />
      <StyledTextField
        name="email"
        label="Recipient's Email"
        errors={errors.email}
        isRequired={true}
        register={register}
      />
      <SFormControl fullWidth>
        <h4>Shipping Method</h4>
        <h4>Express shipping is only on your first box. Standard shipping will apply to your following boxes.</h4>
        <RadioGroup
          {...register('delivery', { required: 'Delivery required' })}
          name="use-radio-group"
          defaultValue="standart">
          <SFormControlLabel value="standart" label="Standart (Delivery time: 5-10 days)" control={<Radio />} />
          <SFormControlLabel value="express" label="Expedited (Delivery time: 3-5 days)" control={<Radio />} />
        </RadioGroup>
      </SFormControl>
    </div>
  );
}
export { ShippingInformationForm };
