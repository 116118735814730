import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
} from '@mui/material';

import { putChangePasswordForm } from 'services';
import {
  ProfileFormButtons,
  Endpoint
} from 'shared';
import './ChangePassword.scss';

type ChangePasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function ChangePassword() {
  const [showPasswordCurrent, setShowPasswordCurrent] = React.useState<boolean>(false);
  const [showPasswordNew, setShowPasswordNew] = React.useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const { apiCall, responseData, error, loading, responseStatus } =
    putChangePasswordForm();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ChangePasswordForm>();

  const onSubmit = (data: ChangePasswordForm) => apiCall(data);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (!loading && responseStatus === 200) {
      toast.success('Succesfully updated information!');
      navigate(Endpoint.PROFILE);
    }
    if (!loading && error && responseStatus !== 200) {
      toast.error('Something went wrong, could not update information!');
    }
  }, [loading, responseStatus, navigate, responseData, error]);

  return (
    <div>
      <h1>Edit Profile</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="password-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('currentPassword', { required: 'Password required' })}
              type={showPasswordCurrent ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordCurrent(!showPasswordCurrent)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.currentPassword)}
              helperText={errors.currentPassword?.message}
              label="Current Password"
            />
          </FormControl>
        </div>
        <div className="password-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('newPassword', { required: 'Password required' })}
              type={showPasswordNew ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordNew(!showPasswordNew)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword?.message}
              label="Confirm New Password"
            />
          </FormControl>
        </div>
        <div className="password-field">
          <FormControl fullWidth>
            <TextField
              fullWidth
              variant="outlined"
              {...register('confirmPassword', {
                required: 'Password required',
                validate: value => value === getValues('newPassword') || 'Password does not match new password'
              })}
              type={showPasswordConfirm ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
              label="Confirm Password"
            />
          </FormControl>
        </div>
        <ProfileFormButtons />
      </form>
    </div>
  );
}

export { ChangePassword };
