import React from 'react';

import { STypeButton, STypeSuccessButton } from '../StyledComponents/Buttons';
import './ButtonSelectionChange.scss';

type ButtonSelectionChangeProps = {
  selectedOption: string;
  displayAsSelected: boolean;
  handleSelection: (value: string) => void;
  displayValue: string;
}

function ButtonSelectionChange(props: ButtonSelectionChangeProps) {
  return (
    <>
      {!props.displayAsSelected &&
        <STypeButton fullWidth onClick={() => props.handleSelection(props.selectedOption)}>{props.displayValue}</STypeButton>
      }
      {props.displayAsSelected &&
        <STypeSuccessButton fullWidth>{props.displayValue}</STypeSuccessButton>
      }
    </>
  );
}

export { ButtonSelectionChange };
