import { useNavigate } from 'react-router';

import { SToMainPageButton, Endpoint } from 'shared';

import './NotFound.scss';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className='not-found-image-wrapper'>
      <div>
        <img className='not-found-image'
          src={require('./not-found.png')}
          srcSet={require('./not-found.png')}
          alt="Not found image"
        />
      </div>
      <div>We could not found the page you are looking, therefore look at this cute dog<div />
        <div>
          <img className='not-found-image'
            src={require('./cute-dog.jpg')}
            srcSet={require('./cute-dog.jpg')}
            alt="Cute dog image"
          />
        </div>
      </div>
      <div><SToMainPageButton onClick={() => navigate(Endpoint.MAIN_PAGE)}>Go to the Home page</SToMainPageButton></div>
    </div >
  );
}

export { NotFound };
