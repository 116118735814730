import { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { HomeComponent } from './components/HomeComponent/HomeComponent';
import { Login } from './components/Login/Login';
import { Register } from './components/Register/Register';
import { AboutUs } from './components/AboutUs/AboutUs';
import { Contact } from './components/Contact/Contact';
import { ProductExplanation } from './components/ProductExplanation/ProductExplanation';
import { ProductOrder } from './components/ProductOrder/ProductOrder';
import { Endpoint } from './shared/Models/Endpoint';
import { ForgotPassword } from './components/ForgotPassword/ForgotPassword';
import { ProductOrderAsGift } from './components/ProductOrderAsGift/ProductOrderAsGift';
import { FrequentlyAskedQuestions } from './components/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import { Profile } from './components/Profile/Profile';
import { EditProfile } from './components/Profile/EditProfile/EditProfile';
import { History } from './components/Profile/History/History';
import { ListOfAnimals } from './components/Profile/ListOfAnimals/ListOfAnimals';
import { Subscription } from './components/Profile/Subscription/Subscription';
import { ChangePassword } from './components/Profile/ChangePassword/ChangePassword';
import { ProfileAddAnimal } from './components/Profile/ListOfAnimals/ProfileAddAnimal/ProfileAddAnimal';
import { ProfileEditAnimal } from './components/Profile/ListOfAnimals/ProfileEditAnimal/ProfileEditAnimal';
import { ProfileViewAnimalInformation } from './components/Profile/ListOfAnimals/ProfileViewAnimalInformation/ProfileViewAnimalInformation';
import { PaymentError } from './components/Payment/PaymentError/PaymentError';
import { PaymentSuccess } from './components/Payment/PaymentSuccess/PaymentSuccess';
import { NotFound } from './components/NotFound/NotFound';
import { UpdateCurrentAnimalSubscription } from 'components/Profile/ListOfAnimals/ProfileViewAnimalInformation/UpdateCurrentAnimalSubscription/UpdateCurrentAnimalSubscription';

const routeOptions: RouteObject[] = [
  {
    element: (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    ),
    children: [
      {
        path: Endpoint.MAIN_PAGE,
        element: <HomeComponent />,
      },
      {
        path: Endpoint.LOGIN,
        element: <Login />,
      },
      {
        path: Endpoint.REGISTER,
        element: <Register />,
      },
      {
        path: Endpoint.ABOUT_US,
        element: <AboutUs />,
      },
      {
        path: Endpoint.CONTACT,
        element: <Contact />,
      },
      {
        path: Endpoint.ABOUT_PRODUCT,
        element: <ProductExplanation />,
      },
      {
        path: Endpoint.ORDER_PRODUCT,
        element: <ProductOrder />,
      },
      {
        path: Endpoint.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: Endpoint.ORDER_PRODUCT_GIFT,
        element: <ProductOrderAsGift />,
      },
      {
        path: Endpoint.FAQ,
        element: <FrequentlyAskedQuestions />,
      },
      {
        path: Endpoint.PROFILE,
        element: <Profile />,
      },
      {
        path: Endpoint.PROFILE_EDIT,
        element: <EditProfile />,
      },
      {
        path: Endpoint.PROFILE_HISTORY,
        element: <History />,
      },
      {
        path: Endpoint.PROFILE_LIST_OF_ANIMALS,
        element: <ListOfAnimals />,
      },
      {
        path: Endpoint.PROFILE_SUBSCRIPTION,
        element: <Subscription />,
      },
      {
        path: Endpoint.PROFILE_CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      {
        path: Endpoint.PROFILE_ADD_ANIMAL,
        element: <ProfileAddAnimal />,
      },
      {
        path: `${Endpoint.PROFILE_EDIT_ANIMAL}/:id`,
        element: <ProfileEditAnimal />,
      },
      {
        path: `${Endpoint.PROFILE_VIEW_INFORMATION}/:id`,
        element: <ProfileViewAnimalInformation />,
      },
      {
        path: `${Endpoint.PROFILE_VIEW_INFORMATION}/:id/subscription`,
        element: <UpdateCurrentAnimalSubscription />,
      },
      {
        path: Endpoint.PAYMENT_ERROR,
        element: <PaymentError />,
      },
      {
        path: Endpoint.PAYMENT_SUCCESS,
        element: <PaymentSuccess />,
      },
    ],
    errorElement: <NotFound />
  },
];

export const routes = routeOptions;
