import { Circle } from '@mui/icons-material';
import { Box } from '@mui/material';

import {
   STypographyValue
} from 'shared';

interface SStatusDisplayProps {
   status: boolean;
   text: string;
}

function SStatusDisplay(props: SStatusDisplayProps) {
   return (
      <Box style={{ display: 'flex' }}>
         <Circle sx={{ color: props.status ? '#04D800' : '#ca0c00', fontSize: '22px' }} />
         <STypographyValue style={{ paddingTop: '2px', paddingLeft: '2px' }}>
            {props.text}
         </STypographyValue>
      </Box>
   );
}

export { SStatusDisplay };
