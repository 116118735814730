import { usePost, useDelete, usePut, useGet } from "utils";
import {
  AnimalInformationByIdResponse,
  AnimalInformationResponse,
  WeightCategory,
} from "shared";

export interface SubscriptionCheckoutInformation {
  checkoutUrl: string;
  subscriptionId: string;
}

export const postAddAnimalForm = () =>
  usePost<{ message: string; animalId: string | undefined }>("/animal");

export const postNewSubscription = () =>
  usePost<SubscriptionCheckoutInformation>("/subscriptions");

export const updateSubscription = (id: string) =>
  usePut<{ message: string }>(`/subscriptions/${id}`);

export const putAddAnimalForm = () => usePut("/animal");

export const getListOfDogs = () => useGet<AnimalInformationResponse>("/animal");

export const getDogById = (id: string) =>
  useGet<AnimalInformationByIdResponse>(`/animal/${id}`);

export const deleteDog = () => useDelete(`/animal`);

export const getSubscriptionItems = () =>
  useGet(`/subscriptions/items/${process.env.PUBLIC_URL}`);

export const subscriptionPriceId = (dogWeight: string) => {
  const mapOfProduct = new Map<string, string>([
    [WeightCategory.SMALL_DOG.toString(), "prod_NYSEdrDFzZcPlY"],
    [WeightCategory.LARGE_DOG.toString(), "prod_O26fWY3uyNvCwd"],
    [WeightCategory.LARGER_DOG.toString(), "prod_O26ksXNAkbLHQO"],
    [WeightCategory.LARGEST_DOG.toString(), "prod_O26m7D4D9i9FHH"],
  ]);

  return mapOfProduct.get(dogWeight) ?? "";
};
