import { Facebook, Instagram, ShoppingCart } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { MenuDrawer } from 'shared';
import './Header.scss';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const expirationDate = localStorage.getItem('expiration');
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (location.pathname === '/'
      || location.pathname === '/about'
      || location.pathname === '/about-us'
      || location.pathname === '/get'
      || location.pathname === '/contact'
      || location.pathname === '/faq'
      || location.pathname === '/login'
      || location.pathname === '/register') {
      return;
    }
    if (location.pathname !== '/login' && (!expirationDate || !token)) {
      navigate('/login');
      return;
    }
    if (location.pathname !== '/login' && expirationDate && new Date(expirationDate as string) <= new Date()) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiration');
      navigate('/login');
      return;
    }
  }, [navigate, location.pathname]);

  return (
    <header>
      <div className="header">
        <div className="first-header">
          <Link to="/" className="first-header1">
            <div className="first-header1-image">
              <img
                src={require('./header-logo.png')}
                srcSet={require('./header-logo.png')}
                alt="Feet logo"
              />
            </div>
            <div className="header-title">JustDogBox</div>
          </Link>
          <div className="first-header-menu">
            <MenuDrawer />
          </div>
        </div>
        <Divider sx={{ borderBottomWidth: 2, color: '#B6AFAF' }}></Divider>
        <div className="second-header">
          <div className="second-header1">
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=100089601083151"
                aria-label="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook sx={{ fontSize: 34 }} />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/justdogbox/"
                aria-label="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram sx={{ fontSize: 34 }} />
              </a>
            </div>
          </div>
          <div className="second-header2">
            <div>
              <ShoppingCart sx={{ fontSize: 34 }} />
            </div>
            <div className="second-header2-cart-number">0</div>
          </div>
        </div>
      </div>
    </header>
  );
}

export { Header };
