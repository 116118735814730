import axios, { Method } from 'axios';
import { useState, useEffect } from 'react';

import { getUrl, getToken } from './authorization';

interface ApiResponse<T> {
    responseData: T | undefined;
    responseStatus: number | undefined;
    error: string | undefined;
    loading: boolean;
}

const useGet = <T>(url: string, payload?: object): ApiResponse<T> => {
    return useRequest(url, 'GET', payload);
};

const useRequest = <T>(url: string, method: Method, payload?: object): ApiResponse<T> => {
    const [responseData, setResponseData] = useState<T>();
    const [responseStatus, setResponseStatus] = useState<number>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const host = getUrl();
    const updatedUrl = `${host}${url}`;

    useEffect(() => {
        (async () => {
            await axios
                .request({
                    data: payload,
                    headers: await getToken(),
                    method,
                    url: updatedUrl,
                })
                .then((response) => {
                    setResponseData(response.data);
                    setResponseStatus(response.status);
                })
                .catch((error) => setError(error.message))
                .finally(() => setLoading(false));
        })();
    }, [method, updatedUrl, payload]);

    return { responseData, error, loading, responseStatus };
};

export { useGet };