import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { CheckCircle } from '@mui/icons-material';
import { Box } from '@mui/material';

import { SToMainPageButton } from 'shared';
import { updateSubscription } from 'services';

import './PaymentSuccess.scss';

function PaymentSuccess() {
  const { responseStatus, loading } = updateSubscription(localStorage.getItem('subscriptionId') ?? '');
  const navigation = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (responseStatus === 200) {
      localStorage.removeItem('subscriptionId');
    }
  });

  return (
    <div className='payment-success'>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CheckCircle style={{ fill: '#008015', fontSize: '84px' }} />
      </Box>
      <h1>Payment successful!</h1>
      <h2>Thank you for the purchase.</h2>
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
        <SToMainPageButton onClick={() => navigation('/')}>Back To Main Page</SToMainPageButton>
      </Box>
    </div>
  );
}

export { PaymentSuccess };
