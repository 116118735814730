import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Fab
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import { produce } from 'immer';

import { getListOfDogs, deleteDog } from 'services';
import {
  DeleteDialog,
  SHeaderWithButton,
  AnimalInformation,
  Endpoint,
} from 'shared';
import { Loading } from '../../Loading/Loading';

import './ListOfAnimals.scss';

function ListOfAnimals() {
  const [dogs, setDogs] = React.useState<AnimalInformation[]>([]);
  const [dog, setDog] = React.useState<AnimalInformation>();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { loading, responseData } = getListOfDogs();
  const { apiCall, responseStatus, loading: deleteLoading } = deleteDog();

  const handleDeleteDog = (id?: string) => {
    apiCall({}, id);
  };

  const handleOpenDialog = (dog: AnimalInformation) => {
    setDog(dog);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate(Endpoint.MAIN_PAGE);
    }
    if (loading) {
      return;
    }
    if (responseData) {
      setDogs(responseData.items);
    }
  }, [loading, responseData, navigate]);

  useEffect(() => {
    if (deleteLoading) {
      return;
    }
    if (!deleteLoading && responseStatus === 200) {
      setDogs(produce(dogs, (dogsDraft) => {
        dogsDraft.splice(dogsDraft.findIndex(o => o.id === dog?.id), 1);
      }));
      toast.success('Dog deleted successfully');
    }
    if (!deleteLoading && responseStatus !== 200) {
      toast.error('Could not delete dog successfully');
    }
  }, [deleteLoading, responseStatus]);

  return <div className="list-of-animals">
    <SHeaderWithButton
      buttonText='Back'
      headerText='List of Dogs'
      navigationLink={Endpoint.PROFILE} />
    {loading ? <Loading /> : (<>
      {dogs.length === 0 && !loading &&
        <div><h1>NO DOGS ADDED</h1></div>
      }
      {dogs.length !== 0 && !loading &&
        dogs.map((dog: AnimalInformation) => (
          <div key={dog.id} className='information-wrapper'>
            <div className='dog-box'>
              <div className='dog-box-information' onClick={() => navigate(`${Endpoint.PROFILE_VIEW_INFORMATION}/${dog.id}`)} >
                <div className='dog-box-title'>{dog.name}</div>
                <div className='dog-box-weight'>{dog.weightCategory}</div>
              </div>
              <div className='dog-action-wrapper'>
                <div><Edit onClick={() => navigate(`${Endpoint.PROFILE_EDIT_ANIMAL}/${dog.id}`)} /></div>
                <div><Delete onClick={() => handleOpenDialog(dog)} /></div>
              </div>
            </div>
          </div>
        ))
      }
      <div className='dog-add-button'>
        <Fab color="primary" aria-label="add" onClick={() => navigate(Endpoint.PROFILE_ADD_ANIMAL)}>
          <Add />
        </Fab>
      </div></>
    )
    }
    <DeleteDialog
      id={dog?.id}
      name={dog?.name}
      open={openDialog}
      onClose={handleCloseDialog}
      onSubmit={handleDeleteDog}
    />
  </div>;
}

export { ListOfAnimals };
