export enum Endpoint {
    MAIN_PAGE = '/',
    LOGIN = '/login',
    REGISTER = '/register',
    ABOUT_US = '/about-us',
    CONTACT = '/contact',
    ABOUT_PRODUCT = '/about',
    ORDER_PRODUCT = '/get',
    FORGOT_PASSWORD = '/forgot-password',
    ORDER_PRODUCT_GIFT = '/get-gift',
    FAQ = '/faq',
    PROFILE = '/profile',
    PROFILE_EDIT = '/profile/edit',
    PROFILE_LIST_OF_ANIMALS = '/profile/list-of-animals',
    PROFILE_HISTORY = '/profile/history',
    PROFILE_SUBSCRIPTION = '/profile/subscription',
    PROFILE_CHANGE_PASSWORD = '/profile/change-password',
    PROFILE_ADD_ANIMAL = '/profile/add-animal',
    PROFILE_EDIT_ANIMAL = '/profile/edit-animal',
    PROFILE_VIEW_INFORMATION = '/profile/details',
    PROFILE_VIEW_INFORMATION_SUSBCRIPTION = '/profile/details/:id/subscription',
    PAYMENT_ERROR = '/payment-error',
    PAYMENT_SUCCESS = '/payment-success',
}